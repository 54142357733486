export const getYearOfDate =(date : string) =>{
     const getYear =  parseInt(date.split(' ')[0].split('/')[2])
     return getYear
 }


 export const getMonthOfDate = (date : string) =>{
    const getMonth =  parseInt(date.split(' ')[0].split('/')[1])
     return getMonth
 }
 